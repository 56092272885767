import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"

export default class extends Controller {
  static targets = ["dateInput", "errorMsg"]

  connect() {
    this.datePicker = flatpickr(this.dateInputTarget, {
      altInput: true,
      altFormat: "d.m.Y",
      dateFormat: "Y-m-d",
      "locale": Russian,
      defaultDate: this.dateInputTarget.value || null,
      onClose: this.checkIfEmpty.bind(this)
    });
  }

  disconnect() {
    if (this.datePicker) {
      this.datePicker.destroy();
      this.datePicker = null;
    }
  }

  checkIfEmpty() {
    const form = this.dateInputTarget.closest('form')
    const selectedValue = this.dateInputTarget.value

    if (form) {
      this.performFormChecks(selectedValue, form);
    }
  }

  performFormChecks(selectedValue, form) {
    const wrapper = this.dateInputTarget.nextElementSibling;
    const submitButton = form.querySelector('#submit-button');

    if (selectedValue) {
      wrapper.removeAttribute("aria-invalid");
      this.errorMsgTarget.classList.add('hidden');
      submitButton.disabled = false;
    } else {
      wrapper.setAttribute("aria-invalid", "aria-invalid");
      this.errorMsgTarget.classList.remove('hidden');
      submitButton.disabled = true;
    }
  }
}
