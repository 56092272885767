import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = ["input", "errorMsg", "submitButton"];

  connect() {
    this.iti = intlTelInput(this.inputTarget, {
      allowDropdown: false,
      countrySearch: false,
      initialCountry: "ru",
      showFlags: false,
      autoInsertDialCode: true,
      onlyCountries: ["ru"],
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.7/build/js/utils.js",
    });
  }

  validate() {
    if (!this.iti) return

    if (this.iti.isValidNumberPrecise()) {
      this.errorMsgTarget.setAttribute("hidden", "hidden");
      this.inputTarget.removeAttribute("aria-invalid");
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      const errorMap = ["Некорректный номер телефона", "Некорректный номер телефона", "Номер телефона слишком короткий", "Номер телефона слишком длинный", "Некорректный номер телефона"];
      this.inputTarget.setAttribute("aria-invalid", "aria-invalid");
      const errorCode = this.iti.getValidationError();
      this.errorMsgTarget.innerHTML = errorMap[errorCode] || "Некорректный номер телефона";
      this.errorMsgTarget.removeAttribute("hidden");
      this.submitButtonTarget.setAttribute("disabled", true);
    }
  }
}
