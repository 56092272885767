import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["location", "startDate", "endDate", "wrapper", "reservationBtn", "errorMsg", "vehicleUnit", "coords"];

  connect() {
    this.loadSavedData()
    this.validate()
    this.startDateTarget.addEventListener('change', this.validate.bind(this))
    this.endDateTarget.addEventListener('change', this.validate.bind(this))
    document.addEventListener('turbolinks:before-visit', this.saveFormData.bind(this))
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-visit', this.saveFormData.bind(this))
  }

  async validate() {
    if (this.locationTarget.value && this.startDateTarget.value && this.endDateTarget.value) {
      this.saveFormData()
      this.checkAvailability()
    } else {
      this.disableButton("Заполните поля, чтобы увидеть наличие доступных дат и забронировать.")
    }
  }

  async checkAvailability() {
    const startDate = this.startDateTarget.value
    const endDate = this.endDateTarget.value
    const vehicleUnit = this.vehicleUnitTarget.value
    const coords = this.coordsTarget.value

    try {
      const response = await fetch(`/reservations/check_availability?start_date=${startDate}&end_date=${endDate}&coords=${coords}&vehicle_unit_id=${vehicleUnit}`)
      const data = await response.json()

      if (data.available) {
        this.enableButton()
      } else {
        this.disableButton(data.message)
      }
    } catch (error) {
      console.error(error)
      this.disableButton("An error occurred while checking availability. Please try again.")
    }
  }

  enableButton() {
    this.reservationBtnTarget.style.pointerEvents = 'auto';
    this.errorMsgTarget.textContent = ""
    this.wrapperTarget.classList.add("border-transparent")
    this.wrapperTarget.classList.remove("border-red-600")
  }

  disableButton(message) {
    this.reservationBtnTarget.style.pointerEvents = 'none';
    this.errorMsgTarget.textContent = message
    this.wrapperTarget.classList.add("border-red-600")
    this.wrapperTarget.classList.remove("border-transparent")
  }

  saveFormData() {
    localStorage.setItem('reservationLocation', this.locationTarget.value)
    localStorage.setItem('reservationStartDate', this.startDateTarget.value)
    localStorage.setItem('reservationEndDate', this.endDateTarget.value)
  }

  loadSavedData() {
    const location = localStorage.getItem('reservationLocation')
    const startDate = localStorage.getItem('reservationStartDate')
    const endDate = localStorage.getItem('reservationEndDate')

    if (location) this.locationTarget.value = location
    if (startDate) this.startDateTarget.value = startDate
    if (endDate) this.endDateTarget.value = endDate
  }
}
