import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['region', 'city', 'address', 'coords']

  save(event) {
    event.preventDefault()

    const form = this.element
    const formData = new FormData(form)
    const csrfToken = document.querySelector('[name="csrf-token"]').content

    fetch(form.action, {
      method: form.method,
        body: formData,
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        }
      })
      .then(response => {
        if (!response.ok) {
          if (response.status === 422) {
            return response.text();
          } else {
          throw new Error(`Network response was not ok: ${response.statusText}`)
          }
        }
        return response.text()
      })
      .then(html => {
        const formDataContainer = document.createElement('div')
        formDataContainer.innerHTML = html
        let innerContent = formDataContainer.querySelector('div turbo-stream template').innerHTML;
        const newForm = document.createElement('div')
        newForm.innerHTML = innerContent
        const updatedForm = newForm.querySelector('.id-vehicle-unit-draft-form')

        if (updatedForm) {
          form.replaceWith(updatedForm)
        } else {
          console.error("Updated form not found in the response")
        }
      })
      .catch(error => {
        console.error("Fetch error:", error)
      })
    }
  }
