import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['location']
  static values = {
    key: String,
    city: String,
    state: String,
    latitude: String,
    longitude: String,
  }

  connect() {
    if (this.keyValue) {
      this.initGetPosition()
    }
  }

  get hasCityAndSate() {
    return Boolean(this.cityValue) && Boolean(this.stateValue)
  }

  get hasLatitudeAndLongitude() {
    return Boolean(this.latitudeValue) && Boolean(this.longitudeValue)
  }

  initGetPosition() {
    if (this.hasCityAndSate) {
      this.setLocationString(this.cityValue, this.stateValue)
    } else {
      if (this.hasLatitudeAndLongitude) {
        this.revealLocationName()
      } else {
        window.navigator.geolocation.getCurrentPosition((position) => {
          this.longitudeValue = position.coords.longitude
          this.latitudeValue = position.coords.latitude

          this.revealLocationName()
        })
      }
    }
  }

  saveCoordinatedToCookies() {
    let latitude = this.latitudeValue
    let longitude = this.longitudeValue
    let city = this.cityValue
    let state = this.stateValue

    fetch('/cookies/set_coords', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ latitude, longitude, city, state }),
    })
  }

  revealLocationName() {
    let key = this.keyValue
    let latitude = this.latitudeValue
    let longitude = this.longitudeValue

    // Make a request to a Geocoding API
    const url = `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=${key}`

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let properties = data['features'][0]['properties']
        let city = properties['city']
        let state

        if (properties['country_code'] === 'us') {
          state = properties['state_code']
        } else {
          state = properties['state_code']
        }

        this.cityValue = city
        this.stateValue = state

        this.setLocationString()
        this.saveCoordinatedToCookies()
      })
  }

  setLocationString() {
    this.locationTarget.innerText = [this.cityValue, this.stateValue].filter(i => i).join(' ,')

    if (Boolean(this.cityValue) && Boolean(this.stateValue)) {
      this.dispatch("geolocationChanged", { detail: { city: this.cityValue, state: this.stateValue } })
    }
  }
}
