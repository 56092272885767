import { Controller } from '@hotwired/stimulus'
import * as FilePond from "filepond"
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

FilePond.registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize)

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

export default class extends Controller {
  static targets = ["input", "errorMsg"]

  connect() {
    this.pond = FilePond.create(this.inputTarget, {
      allowMultiple: true,
      labelIdle: `<p class='flex items-center text-base cursor-pointer'><span class='mr-3'><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path d="M42.8574 9.86719H35.5717L33.836 5.0029C33.7161 4.67005 33.4963 4.38234 33.2068 4.17907C32.9172 3.97579 32.5719 3.86688 32.2181 3.86719H15.7824C15.0592 3.86719 14.411 4.32255 14.1699 5.0029L12.4289 9.86719H5.14314C2.77528 9.86719 0.857422 11.785 0.857422 14.1529V38.5815C0.857422 40.9493 2.77528 42.8672 5.14314 42.8672H42.8574C45.2253 42.8672 47.1431 40.9493 47.1431 38.5815V14.1529C47.1431 11.785 45.2253 9.86719 42.8574 9.86719ZM43.286 38.5815C43.286 38.8172 43.0931 39.01 42.8574 39.01H5.14314C4.90742 39.01 4.71456 38.8172 4.71456 38.5815V14.1529C4.71456 13.9172 4.90742 13.7243 5.14314 13.7243H15.1449L16.061 11.1636L17.2878 7.72433H30.7074L31.9342 11.1636L32.8503 13.7243H42.8574C43.0931 13.7243 43.286 13.9172 43.286 14.1529V38.5815ZM24.0003 17.1529C19.2646 17.1529 15.4289 20.9886 15.4289 25.7243C15.4289 30.46 19.2646 34.2958 24.0003 34.2958C28.736 34.2958 32.5717 30.46 32.5717 25.7243C32.5717 20.9886 28.736 17.1529 24.0003 17.1529ZM24.0003 30.8672C21.161 30.8672 18.8574 28.5636 18.8574 25.7243C18.8574 22.885 21.161 20.5815 24.0003 20.5815C26.8396 20.5815 29.1431 22.885 29.1431 25.7243C29.1431 28.5636 26.8396 30.8672 24.0003 30.8672Z" fill="#F6A945"/>
      </svg></span> Загрузить фотографии</p>`,
      labelMaxFileSizeExceeded: 'Файл больше допустимого размера',
      acceptedFileTypes: ['image/png', 'image/jpg', 'image/gif', 'image/jpeg'],
      imagePreviewHeight: 180,
      maxFileSize: "12MB",
      maxFiles: 12
    })

    this.pond.on('addfile', (error, file) => {
      if (error) {
        console.error('Error adding file', error)
        this.pond.removeFile(file)
        this.errorMsgTarget.innerHTML = `${error.main}`;
        this.errorMsgTarget.classList.remove("hidden");
      } else {
        console.log('File added', file)
        this.errorMsgTarget.classList.add("hidden");

        this.updateInputField()
      }
    })

    this.pond.on('removefile', (error, file) => {
        this.updateInputField()
    })
  }


  disconnect() {
    this.pond.destroy()
  }

  updateInputField() {
    const extraInputs = document.querySelectorAll('input[type="hidden"][name="vehicle_unit_draft[photos][images][]"]')
    extraInputs.forEach(input => input.remove());

    const files = this.pond.getFiles();
    const input = document.querySelector("input[type='file']")
    input.setAttribute("name", "vehicle_unit_draft[photos][images][]")

    const dataTransfer = new DataTransfer();

    files.forEach(fileItem => {
      dataTransfer.items.add(fileItem.file);
    });

    input.files = dataTransfer.files;
  }
}
