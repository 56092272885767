import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "form", "errorMsg", "submitButton", "secondButton"];

  vinValidation() {
    const value = this.inputTarget.value.trim();
    const messages = []

    if (value.length !== 17) {
      messages.push('VIN номер должен содержать 17 символов')
    }

    if (!/^[A-HJ-NPR-Z0-9]+$/i.test(value)) {
      messages.push('Некорректный VIN номер')
    }

    if (messages.length > 0) {
      this.errorMsgTarget.innerHTML = messages[0];
      this.errorMsgTarget.removeAttribute("hidden");
      this.inputTarget.setAttribute("aria-invalid", "aria-invalid");
      this.submitButtonTarget.setAttribute("disabled", "disabled");
    } else {
      this.inputTarget.removeAttribute("aria-invalid");
      this.errorMsgTarget.setAttribute("hidden", "hidden");
      this.submitButtonTarget.removeAttribute("disabled");
    }
  }

  checkAllFields() {
    const fields = this.formTarget.querySelectorAll('input, select, textarea')
    const isValid = Array.from(fields).every(field => field.checkValidity())

    this.submitButtonTarget.disabled = !isValid;
  }

  setInvalidState(target, errorMsg) {
    target.setAttribute("aria-invalid", "aria-invalid");
    errorMsg.classList.remove("hidden");
    this.submitButtonTarget.disabled = true;
  }

  setValidState(target, errorMsg) {
    target.removeAttribute("aria-invalid");
    errorMsg.classList.add("hidden");

    if(this.formTarget) {
      this.checkAllFields()
    }
  }

  requiredValidation(event) {
    const input = event.target
    const errorMsg = input.closest('.input-wrapper').nextElementSibling
    const value = input.value.trim();


    if (value) {
      this.setValidState(input, errorMsg)
    } else {
      errorMsg.innerHTML = 'Обязательно к заполнению';
      this.setInvalidState(input, errorMsg)
    }
  }
}
