import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["location", "startDate", "endDate", "shiftPrice", "fullPrice", "startDateField", "endDateField", "locationField"];

  connect() {
    this.loadSavedData()
  }
  
  
  loadSavedData() {
    const location = localStorage.getItem('reservationLocation')
    const startDate = localStorage.getItem('reservationStartDate')
    const endDate = localStorage.getItem('reservationEndDate')
    const shiftPrice = this.shiftPriceTarget.innerHTML
    const fullPrice = this.calculatePrice(startDate, endDate, shiftPrice)
    
    if (location) {
      this.locationTarget.innerHTML = location
      this.locationFieldTarget.value = location
    }
    if (startDate) {
      this.startDateTarget.innerHTML = startDate
      this.startDateFieldTarget.value = startDate
    }
    if (endDate) {
      this.endDateTarget.innerHTML = endDate
      this.endDateFieldTarget.value = endDate
    }
    console.log(fullPrice)
    if (fullPrice) this.fullPriceTarget.innerHTML = `₽ ${this.formatNumberWithSpaces(fullPrice)}`
  }
  
  calculatePrice(startDate, endDate, shiftPrice) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = Math.abs(end - start);
    let numberOfDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1; // Convert milliseconds to days

    const price = shiftPrice * numberOfDays;
    return price;
  }

  formatNumberWithSpaces(number) {
    const numberString = number.toString();
    const parts = [];
    let i = numberString.length;

    while (i > 0) {
      parts.unshift(numberString.substring(Math.max(0, i - 3), i));
      i -= 3;
    }

    return parts.join(' ');
  }
}
