import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["poaFields", "actOnBasisSelect"]

  connect() {
    console.log("act_on_basis_controller connected")
    this.togglePOAFields()
  }

  togglePOAFields() {
    console.log(this.actOnBasisSelectTarget)
    console.log(this.poaFieldsTarget)
    const selectedValue = this.actOnBasisSelectTarget.value
    if (selectedValue === "Доверенность") {
      this.poaFieldsTarget.classList.remove("hidden")
    } else {
      this.poaFieldsTarget.classList.add("hidden")
    }
  }
}
