import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleBtn", "hiddenContent"]

  toggleContent() {
    const isHidden = this.hiddenContentTarget.hasAttribute("hidden");

    this.hiddenContentTarget.toggleAttribute("hidden", !isHidden);

    if (isHidden) {
      this.updateClasses(this.hiddenContentTarget, ["h-0", "opacity-0"], ["h-fit", "opacity-1"]);
    } else {
      this.updateClasses(this.hiddenContentTarget, ["h-fit", "opacity-1"], ["h-0", "opacity-0"]);
    }

    this.renderButtonContent();
  }

  updateClasses(element, classesToRemove, classesToAdd) {
    element.classList.remove(...classesToRemove);
    element.classList.add(...classesToAdd);
  }

  renderButtonContent() {
    const isHidden = this.hiddenContentTarget.hasAttribute("hidden");
    const svgContent = `
      <svg xmlns="http://www.w3.org/2000/svg" class="${isHidden ? '' : 'rotate-180'}" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0491 12.352C20.274 12.577 20.4004 12.8822 20.4004 13.2004C20.4004 13.5186 20.274 13.8238 20.0491 14.0488L12.8491 21.2488C12.6241 21.4738 12.3189 21.6001 12.0007 21.6001C11.6825 21.6001 11.3773 21.4738 11.1523 21.2488L3.95228 14.0488C3.83767 13.9381 3.74625 13.8057 3.68336 13.6593C3.62047 13.5129 3.58737 13.3554 3.58598 13.1961C3.5846 13.0367 3.61496 12.8787 3.6753 12.7312C3.73563 12.5838 3.82474 12.4498 3.93741 12.3371C4.05008 12.2244 4.18406 12.1353 4.33154 12.075C4.47901 12.0147 4.63703 11.9843 4.79637 11.9857C4.9557 11.9871 5.11316 12.0202 5.25957 12.0831C5.40597 12.146 5.53839 12.2374 5.64908 12.352L10.8007 17.5036V3.60039C10.8007 3.28213 10.9271 2.97691 11.1522 2.75186C11.3772 2.52682 11.6824 2.40039 12.0007 2.40039C12.3189 2.40039 12.6242 2.52682 12.8492 2.75186C13.0743 2.97691 13.2007 3.28213 13.2007 3.60039V17.5036L18.3523 12.352C18.5773 12.127 18.8825 12.0006 19.2007 12.0006C19.5189 12.0006 19.8241 12.127 20.0491 12.352Z" fill="currentColor"/>
      </svg>
    `;
    this.toggleBtnTarget.innerHTML = `<span class="mr-2">${svgContent}</span> ${isHidden ? 'Показать больше' : 'Показать меньше'}`;
  }
}
