import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"

export default class extends Controller {
  static targets = ["startDate", "endDate"]

  connect() {
    this.minStartDate = this.startDateTarget.hasAttribute("data-is-start-today") ? "today" : ''
    this.minEndDate = this.endDateTarget.hasAttribute("data-is-start-today") ? "today" : ''

    this.startDatePicker = flatpickr(this.startDateTarget, {
      altInput: true,
      altFormat: "d.m.Y",
      dateFormat: "Y-m-d",
      minDate: this.minStartDate,
      maxDate: this.endDateTarget.value,
      "locale": Russian,
      defaultDate: this.startDateTarget.value || null,
      onReady: this.addClearButton.bind(this),
      onClose: this.closeStart.bind(this)
    });

    this.endDatePicker = flatpickr(this.endDateTarget, {
      altInput: true,
      altFormat: "d.m.Y",
      dateFormat: "Y-m-d",
      minDate: this.minEndDate,
      "locale": Russian,
      defaultDate: this.endDateTarget.value || null,
      onReady: this.addClearButton.bind(this),
      onClose: this.closeEnd.bind(this)
    });
  }

  disconnect() {
    if (this.startDatePicker) {
      this.startDatePicker.destroy();
      this.startDatePicker = null;
    }

    if (this.endDatePicker) {
      this.endDatePicker.destroy();
      this.endDatePicker = null;
    }
  }

  closeStart(_, dateStr) {
    this.endDatePicker.set("minDate", dateStr || this.minEndDate);
  }

  closeEnd(_, dateStr) {
    this.startDatePicker.set("maxDate", dateStr);
  }

  addClearButton(_, __, instance) {
    const clearButton = document.createElement("button");
    clearButton.type = "button";
    clearButton.className = "flatpickr-clear-button";
    clearButton.textContent = "Очистить";

    clearButton.addEventListener("click", () => {
      instance.clear();
      instance.close();
    });

    instance.calendarContainer.appendChild(clearButton);
  }
}
