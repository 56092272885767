import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "description", "descriptionError",
    "licensePlate", "licensePlateError",
    "radioButtonTrue", "radioButtonFalse",
    "stateDescription", "stateDescriptionError",
    "form", "submitButton"
  ]

  connect() {
    console.log("Form validation controller connected");
  }

  checkAllFields() {
    const fields = this.formTarget.querySelectorAll('input, select, textarea')
    const fileInput = Array.from(fields).find(input => input.type === 'file')

    const isValidNumberOfFiles = fileInput.files.length > 3

    const isValid = Array.from(fields).every(field => field.checkValidity())

    this.submitButtonTarget.disabled = !isValid && isValidNumberOfFiles;
  }

  setInvalidState(target, errorMsg) {
    target.setAttribute("aria-invalid", "aria-invalid");
    errorMsg.classList.remove("hidden");
    this.submitButtonTarget.disabled = true;
  }

  setValidState(target, errorMsg) {
    target.removeAttribute("aria-invalid");
    errorMsg.classList.add("hidden");
    this.checkAllFields()
  }

  requiredValidation(event) {
    const input = event.target
    const errorMsg = input.closest('.input-wrapper').nextElementSibling
    const value = input.value.trim();


    if (value) {
      this.setValidState(input, errorMsg)
    } else {
      errorMsg.innerHTML = 'Обязательно к заполнению';
      this.setInvalidState(input, errorMsg)
    }
  }

  validateDescription() {
    const description = this.descriptionTarget.value.trim();
    if (description.length >= 100 && description.length <= 500) {
      this.setValidState(this.descriptionTarget, this.descriptionErrorTarget)
    } else {
      this.setInvalidState(this.descriptionTarget, this.descriptionErrorTarget)

      if (description.length < 100) {
        this.descriptionErrorTarget.textContent = "Описание слишком короткое. Должно быть не менее 100 символов.";
      } else {
        this.descriptionErrorTarget.textContent = "Описание слишком длинное. Должно быть не более 500 символов.";
      }
    }
  }

  validateLicensePlate() {
    const licensePlate = this.licensePlateTarget.value.trim();

    if (licensePlate.length > 0 && /^(?=.*\d)[A-Za-zа-яА-Я0-9]{3,20}$/gm.test(licensePlate)) {
      this.setValidState(this.licensePlateTarget, this.licensePlateErrorTarget)
    } else {
      this.setInvalidState(this.licensePlateTarget, this.licensePlateErrorTarget)

      if (!licensePlate) {
        this.licensePlateErrorTarget.textContent = "Обязательно к заполнению";
      } else {
        this.licensePlateErrorTarget.textContent = "Некорректный гос. номер";
      }
    }
  }

  updateStateDescription() {
    if (this.radioButtonFalseTarget.checked) {
      this.stateDescriptionErrorTarget.textContent = "Обязательно к заполнению"
      this.stateDescriptionTarget.setAttribute("required", "required")
      this.setInvalidState(this.stateDescriptionTarget, this.stateDescriptionErrorTarget)
    } else {
      this.stateDescriptionTarget.removeAttribute("required")
      this.setValidState(this.stateDescriptionTarget, this.stateDescriptionErrorTarget)
    }
  }

  validateStateDescription() {
    if (this.radioButtonTrueTarget.checked) return

    const description = this.stateDescriptionTarget.value.trim()

    if (description.length >= 20 && description.length <= 500) {
      this.setValidState(this.stateDescriptionTarget, this.stateDescriptionErrorTarget)
    } else {
      this.setInvalidState(this.stateDescriptionTarget, this.stateDescriptionErrorTarget)

      if (description.length < 20) {
        this.stateDescriptionErrorTarget.textContent = "Описание слишком короткое. Должно быть не менее 20 символов.";
      } else {
        this.stateDescriptionErrorTarget.textContent = "Описание слишком длинное. Должно быть не более 500 символов.";
      }
    }
  }
}
