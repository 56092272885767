import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["aasmState", "startDate", "endDate"]

  connect() {
    // Initialize date pickers if using a date picker library
  }

  filter() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const aasmState = this.aasmStateTarget.value;
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value;

    if (aasmState) {
      params.set("aasm_state", aasmState);
    } else {
      params.delete("aasm_state");
    }

    if (startDate) {
      params.set("start_date", startDate);
    } else {
      params.delete("start_date");
    }

    if (endDate) {
      params.set("end_date", endDate);
    } else {
      params.delete("end_date");
    }

    url.search = params.toString();
    Turbo.visit(url.toString());
  }
}
