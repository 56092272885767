import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['dropdownContainer', 'disclosureContainer']
  static values = { toggle: { type: String, default: 'is-open' } }

  connect() {
    if (this.hasDropdownContainerTarget) {
      useClickOutside(this)
    }
  }

  toggle() {
    if (this.hasDropdownContainerTarget) {
      this.dropdownContainerTarget.classList.toggle(this.toggleValue)
    } else {
      this.disclosureContainerTarget.classList.toggle(this.toggleValue)
    }
  }

  clickOutside() {
    this.dropdownContainerTarget.classList.remove(this.toggleValue)
  }
}
