const getLoader = async (options) => {
    const remoteScriptLoader = require('remote-script-loader');

    return new Promise((resolve) => {
        if (typeof ymaps3 !== 'undefined') {
            return ymaps3.ready.then(() => resolve(ymaps3));
        }
        return remoteScriptLoader.loadScriptOnce(
            'https://api-maps.yandex.ru/v3/?apikey=' + options.apiKey +
            '&lang=' + (options.lang || 'ru') + '_' + (options.region || 'RU')
        ).then(function () { ymaps3.ready.then(() => resolve(ymaps3)) });
    });
}

export { getLoader }