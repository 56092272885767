// app/javascript/controllers/entity_autosave_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  save(event) {
    event.preventDefault() // Prevent default form submission

    const form = this.element
    const formData = new FormData(form)
    const csrfToken = document.querySelector('[name="csrf-token"]').content

    fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        'X-CSRF-Token': csrfToken,
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`)
        }
        return response.text()
      })
      .then(html => {
        const newForm = document.createElement('div')
        newForm.innerHTML = html
        const updatedForm = newForm.querySelector('#entity_update_form')
        if (updatedForm) {
          form.replaceWith(updatedForm)
        } else {
          console.error("Updated form not found in the response")
        }
      })
      .catch(error => {
        console.error("Fetch error:", error)
      })
  }
}
