import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ['select', 'errorMsg']

  connect() {
    this.isMultiple = this.selectTarget.hasAttribute('multiple')
    this.placeholderValue = this.selectTarget.getAttribute('placeholder')
    this.isSearchEnabled = this.selectTarget.getAttribute('data-enable-search')

    this.choices = new Choices(this.selectTarget, {
      removeItemButton: this.isMultiple ? true : false,
      allowHTML: true,
      searchEnabled: this.isSearchEnabled ? true : false,
      itemSelectText: '',
      placeholder: true,
      placeholderValue: this.placeholderValue,
      noResultsText: 'Ничего не найдено',
      noChoicesText: 'Нет вариантов для выбора',
      classNames: {
        itemChoice: 'choices-item',
        highlightedState: 'is-hover',
      },
    });

    this.selectedValues = this.choices.getValue(true); // getValue(true) returns an array of selected values
    this.searchInput = this.selectTarget.closest(".choices__inner").querySelector("input");

    this.hideInput(this.selectedValues, this.searchInput)
    this.updateVisibleTags()

    this.selectTarget.addEventListener('change', () => this.checkIfEmpty())

    this.selectTarget.addEventListener('addItem', () => this.updateVisibleTags())
    this.selectTarget.addEventListener('removeItem', () => this.updateVisibleTags())

    window.addEventListener('resize', () => this.updateVisibleTags());
  }

  disconnect() {
    this.choices.destroy();
  }

  hideInput(selectedValues, searchInput) {
    if (this.isMultiple) {
      if (selectedValues.length === 0) {
        searchInput.removeAttribute('hidden');
      } else {
        searchInput.setAttribute('hidden', 'hidden');
      }
    }
  }

  checkIfEmpty() {
    const selectedValues = this.choices.getValue(true)

    this.hideInput(selectedValues, this.searchInput)

    const form = this.selectTarget.closest('form')
    if (form) {
      this.performFormChecks(selectedValues, form);
    }
  }

  performFormChecks(selectedValues, form) {
    const wrapper = this.selectTarget.closest('.choices');
    const submitButton = form.querySelector('#submit-button');

    if (selectedValues.length === 0) {
      wrapper.classList.add('invalid');
      this.errorMsgTarget.classList.remove('hidden');
      submitButton.disabled = true;
    } else {
      wrapper.classList.remove('invalid');
      this.errorMsgTarget.classList.add('hidden');
      submitButton.disabled = false;
    }
  }

  updateVisibleTags() {
    if (!this.isMultiple) return

    const choiceList = this.element.querySelector('.choices__list--multiple');
    const items = Array.from(choiceList.querySelectorAll('.choices__item'));
    const inputWidth = this.element.querySelector('.choices__inner').offsetWidth - 90;

    let totalTagsWidth = 0;
    const overflowItems = [];

    items.forEach(item => {
      totalTagsWidth += item.offsetWidth;
      if (totalTagsWidth > inputWidth) {
        item.style.display = 'none';
        overflowItems.push(item);
      } else {
        item.style.display = 'inline-block';
      }
    });

    this.updateOverflowElement(choiceList, overflowItems);
  }

  updateOverflowElement(choiceList, overflowItems) {
    let overflowElement = choiceList.querySelector('.choices__overflow');

    if (overflowItems.length > 0) {
      const hiddenTagsNames = overflowItems.map(item => item.getAttribute("data-value")).join(", ");

      if (!overflowElement) {
        overflowElement = document.createElement('div');
        overflowElement.className = 'choices__item choices__overflow';
        overflowElement.style.backgroundColor = '#F6A945';
        overflowElement.style.padding = '2px 10px';
        choiceList.appendChild(overflowElement);
      }

      overflowElement.textContent = `+${overflowItems.length}`;
      overflowElement.setAttribute('title', hiddenTagsNames);
    } else if (overflowElement) {
      overflowElement.remove();
    }
  }
}
