import { Controller } from "@hotwired/stimulus"

export default class ModalController extends Controller {
    connect() {
      const options = {
        placement: 'top-center',
        backdrop: 'dynamic',
        backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        closable: false,
      };

      this.modal = new Modal(this.element, options)
      this.modal.show()
    }

    close(event) {
      this.modal.hide()
    }

    disconnect() {
      this.modal.hide()
    }
  }
