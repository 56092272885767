import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option"]

  connect() {
    console.log("Vehicle Type Controller connected")
    this.options = this.optionTargets
    this.hiddenInput = document.getElementById("vehicle_type")
    this.selectOptionById(this.hiddenInput.value)
  }

  select(event) {
    this.hiddenInput.value = event.currentTarget.dataset.id
  }

  selectOptionById(id) {
    const selectedOption = this.options.find(option => option.dataset.id === id)
  }
}
