import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['location', 'coords']
    static values = {
        key: String
    }

    getCoords() {
        let key = this.keyValue;
        let location = this.locationTarget.value
        console.log(location)
        const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${key}&geocode=${location}&format=json`

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                this.coordsTarget.value = data['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['Point']['pos']
            })
    }
}
