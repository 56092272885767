import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["location", "startDate", "endDate"]

  connect() {
    this.loadSavedData()
    document.addEventListener('turbolinks:before-visit', this.saveFormData.bind(this))
  }

  disconnect() {
    this.saveFormData()
    document.removeEventListener('turbolinks:before-visit', this.saveFormData.bind(this))
  }

  saveFormData() {
    localStorage.setItem('reservationLocation', this.locationTarget.value)
    localStorage.setItem('reservationStartDate', this.startDateTarget.value)
    localStorage.setItem('reservationEndDate', this.endDateTarget.value)
  }

  loadSavedData() {
    const location = localStorage.getItem('reservationLocation')
    const startDate = localStorage.getItem('reservationStartDate')
    const endDate = localStorage.getItem('reservationEndDate')

    if (location) this.locationTarget.value = location
    if (startDate) this.startDateTarget.value = startDate
    if (endDate) this.endDateTarget.value = endDate
  }
}
