import { Controller } from '@hotwired/stimulus'
import { getLoader } from '../yandex_library_loader'

export default class extends Controller {
  static outlets = [ "vehicle-autosave" ]

  static values = {
    key: String,
    zoom: Number,
    centerLatitude: Number,
    centerLongitude: Number,
    fullMapCenterLatitude: Number,
    fullMapCenterLongitude: Number,
    searchCenterLatitude: Number,
    searchCenterLongitude: Number,
  }

  static targets = ['container', 'mapContainer', 'closeButton']

  connect() {
    this.initMap()
    this.yMap = null
  }

  async initMap() {
    await getLoader({ apiKey: this.keyValue})

    const {YMap, YMapDefaultSchemeLayer, YMapListener, YMapDefaultFeaturesLayer, YMapMarker} = ymaps3;
    const {YMapDefaultMarker} = await ymaps3.import('@yandex/ymaps3-markers@0.0.1');

    let centerLatitude = this.centerLatitudeValue;
    let centerLongitude = this.centerLongitudeValue;
    let container = this.containerTarget;
    let zoom = this.zoomValue;
    const map = new YMap(
        container,
        {
          location: {
            center: [centerLongitude, centerLatitude],
            zoom: zoom
          }
        }
    );

    this.yMap = map;
    this.marker = null;
    map.addChild(new YMapDefaultSchemeLayer());
    map.addChild(new YMapDefaultFeaturesLayer());

    const markerUpdate = (object,event) => {
      this.getAddress(event.coordinates, YMapDefaultMarker);
    }

    const mapListener = new YMapListener({
      layer: 'any',
      onClick: markerUpdate,
    });

    map.addChild(mapListener);
  }

  resetMapPosition() {
    if (Boolean(this.centerLatitudeValue) && Boolean(this.centerLongitudeValue)) {
      this.googleMap.setCenter(new google.maps.LatLng(this.centerLatitudeValue, this.centerLongitudeValue))
    }
  }

  setMapCenter({ detail: { latitude, longitude } }) {
    if (Boolean(latitude) && Boolean(longitude)) {
      this.googleMap.setCenter(new google.maps.LatLng(latitude, longitude))
    } else {
      this.resetMapPosition()
    }
  }

  getAddress(coords, YMapDefaultMarker) {
    let key = this.keyValue;

    const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${key}&geocode=${coords}&format=json`

    fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let address = data['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['metaDataProperty']['GeocoderMetaData']['Address']

          let full_address = address['formatted'].split(',')
          let street_address = full_address.slice(2, full_address.length).join(',')

          let region = address['Components'][2]['name']
          let city = null

          if (address['Components'][3]['name'] != null) {
           city = address['Components'][3]['name']
         } else {
           city = address['Components'][2]['name']
         }


          if (this.marker == null) {
            this.marker = new YMapDefaultMarker({
              coordinates: coords,
              subtitle: street_address,
              color: 'red'
            })
            this.yMap.addChild(this.marker);
          } else {
            this.marker.update({
                coordinates: coords,
                subtitle: street_address
            })
          }


          this.vehicleAutosaveOutlet.regionTarget.value = region;
          this.vehicleAutosaveOutlet.cityTarget.value = city;
          this.vehicleAutosaveOutlet.addressTarget.value = street_address;
          this.vehicleAutosaveOutlet.coordsTarget.value = coords
        })
  }
}
