import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["tab"]

    switchTab(event) {
        const currentTab = event.currentTarget;
        const tabs = this.element.querySelectorAll("button[role='tab']")

        currentTab.classList.add('active-tab');

        Array.from(tabs).filter(tab => tab.dataset.tabId !== currentTab.dataset.tabId).forEach(tab => tab.classList.remove('active-tab'))

    }
}
