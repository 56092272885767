import { Controller } from "@hotwired/stimulus"

export default class ModalController extends Controller {
    static targets = ["sidebar", "mainContent", "filterBtn"];

    connect() {
        this.updateSidebarBehavior();
        window.addEventListener('resize', this.updateSidebarBehavior.bind(this));
    }

    disconnect() {
        window.removeEventListener('resize', this.updateSidebarBehavior.bind(this));
    }

    open() {
        this.sidebarTarget.classList.remove("-translate-x-full")
        this.sidebarTarget.classList.add("translate-x-0")

        if (window.innerWidth >= 1024) {
            this.sidebarTarget.classList.remove("hidden")
        }

        this.filterBtnTarget.classList.add("border-primary-orange")
        this.filterBtnTarget.classList.remove("border-white")
    }

    close() {
        this.sidebarTarget.classList.add("-translate-x-full")
        this.sidebarTarget.classList.remove("translate-x-0")


        if (window.innerWidth >= 1024) {
            this.sidebarTarget.classList.add("hidden")
        }

        this.filterBtnTarget.classList.remove("border-primary-orange")
        this.filterBtnTarget.classList.add("border-white")
    }

    updateSidebarBehavior() {
        if (window.innerWidth >= 1024) {
            this.sidebarTarget.classList.remove("hidden")
            this.sidebarTarget.classList.remove("-translate-x-full")
        } else {
            this.sidebarTarget.classList.remove("translate-x-0")
            this.sidebarTarget.classList.add("-translate-x-full")
        }
    }
}
