import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["pricePerHour", "shiftPrice"];

  connect() {
    console.log("Connected to stimulus controller");
  }

  calculateShiftPrice() {
    const pricePerHour = parseFloat(this.pricePerHourTarget.value);
    if (!isNaN(pricePerHour)) {
      const shiftPrice = pricePerHour * 8; // Adjust calculation as needed
      this.shiftPriceTarget.value = shiftPrice.toFixed(2);
    } else {
      this.shiftPriceTarget.value = "";
    }
  }
}
