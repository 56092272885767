import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["minValue", "maxValue", "minOutput", "maxOutput", "progress"]

  connect() {
    this.minInput = document.getElementById("min_shift_price_input")
    this.maxInput = document.getElementById("max_shift_price_input")
    this.updateOutputs()
  }

  updateOutputs() {
    const minValue = parseInt(this.minValueTarget.value, 10)
    const maxValue = parseInt(this.maxValueTarget.value, 10)
    const maxRangeValue = parseInt(this.minValueTarget.max, 10)
    const minRangeValue = parseInt(this.minValueTarget.min, 10)

    if (minValue > maxValue) {
      this.minValueTarget.value = maxValue
      this.minOutputTarget.textContent = maxValue
      this.minInput.value = maxValue
    } else {
      this.minOutputTarget.textContent = minValue
      this.minInput.value = minValue
    }

    this.maxOutputTarget.textContent = maxValue
    this.maxInput.value = maxValue

    this.progressTarget.style.left = `${((minValue - minRangeValue) / (maxRangeValue - minRangeValue)) * 100}%`
    this.progressTarget.style.right = `${98 - ((maxValue - minRangeValue) / (maxRangeValue - minRangeValue)) * 100
  }%`
  }
}
