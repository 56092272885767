import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['showpassword_button']
  static values = { toggle: { type: String, default: 'is-shown' } }

  toggle() {
    const input = this.showpassword_buttonTarget.closest('.input-wrapper').querySelector('input')

    this.showpassword_buttonTarget.classList.toggle(this.toggleValue)

    if (this.showpassword_buttonTarget.classList.contains('is-shown')) {
      input.type = 'text'
    } else {
      input.type = 'password'
    }
  }
}
